<template>
  <Header />
  <Herosection />
  <RosaryPrayers />
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Herosection from "@/components/HeroSectionPage.vue";
import RosaryPrayers from "@/components/prayers/RosaryPrayers.vue";

export default {
  components: {
    Header,
    Footer,
    Herosection,
    RosaryPrayers,
  },
};
</script>

<template>
  <section class="hero-area overlay" id="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12"></div>
        <div class="col-lg-6 col-md-12 col-12 saintbox">
          <h1 class="hero-content bannerheading text-center">
            The Lord Is My<br />
            Shepherd
          </h1>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.bannerheading {
  padding: 130px 0px 40px 0px;
  color: #fff;
  font-size: 50px;
  line-height: 80px;
  font-weight: 600;
  letter-spacing: 5px;
  text-transform: uppercase;
  cursor: pointer;
}
.saintbox {
  position: relative;
  border-radius: 100px;
  padding: 0px 50px;
  text-align: justify;
  cursor: pointer;
}
.hero-area:before {
  content: "";
  background: rgba(6, 12, 34, 0.5);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.hero-area {
  position: relative;
  width: 100%;
  padding: 20px 0px;
  background-image: url("../assets/images/Banner-Page.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .hero-area .hero-content h1 {
    font-size: 50px;
    line-height: 70px;
  }
  .circle-content {
    padding: 60px;
    width: 60%;
  }
}
@media only screen and (min-width: 901px) and (max-width: 991px) {
  .hero-area .hero-content {
    text-align: center;
  }
  .hero-area .hero-content h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 70px;
  }
  .circle-content {
    padding: 40px;
    width: 60%;
  }
}
@media only screen and (min-width: 831px) and (max-width: 900px) {
  .hero-area .hero-content h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
  }
  .circle-content {
    padding: 40px;
    width: 60%;
  }
}
@media only screen and (min-width: 801px) and (max-width: 830px) {
  .hero-area .hero-content h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
  }
  .circle-content {
    padding: 40px;
    width: 60%;
  }
}
@media only screen and (min-width: 701px) and (max-width: 800px) {
  .hero-area .hero-content h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 65px;
  }
}
@media only screen and (min-width: 1501px) and (max-width: 2561px) {
  .hero-content h1 {
    font-size: 100px !important;
    line-height: 130px !important;
  }
  .circle-content {
    width: 60%;
  }
  .hero-area {
    padding: 150px 0px !important ;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .hero-content h1 {
    font-size: 75px !important;
    line-height: 110px !important;
  }
  .circle-content {
    width: 60%;
  }
}
@media only screen and (min-width: 601px) and (max-width: 700px) {
  .hero-area .hero-content h1 {
    font-size: 35px;
    font-weight: 700;
    line-height: 60px;
  }
}
@media only screen and (min-width: 401px) and (max-width: 600px) {
  .hero-area .hero-content h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
  }
  .circle-content {
    padding: 60px;
    width: 70%;
  }
}
@media only screen and (min-width: 0px) and (max-width: 400px) {
  .hero-content h1 {
    font-size: 30px !important;
    line-height: 50px !important;
  }
  .circle-content {
    padding: 50px;
    width: 70%;
  }
}
@media (max-width: 998px) {
  .hero-area .hero-content {
    padding: 0 10px;
    font-size: 38px;
  }
  .hero-area .hero-content {
    margin: 0;
  }
}

.hero-area .hero-content {
  border-radius: 0;
  position: relative;
  overflow: hidden;
  margin-top: 40px;
  z-index: 1;
}
.hero-content h1 {
  color: #fff;
  font-size: 60px;
  line-height: 80px;
  font-weight: 600;
  text-transform: uppercase;
}
.hero-content h1 span {
  color: #fff;
}
</style>

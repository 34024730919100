<template>
  <section id="about" class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-12 aboutsection">
          <a href="../../prayers" class="cta-link">Back to prayers</a>
          <h2 class="pt-4" style="color: #0b416c">Rosary Prayers</h2>
          <hr />
          <div class="section-title mt-2">
            <p>
              The Rosary prayers are the Apostles Creed, Our Father, Hail Marys,
              Glory Be, and, if desired, the Fatima Prayer. Next come five
              mysteries. Rosary prayers conclude with the Hail Holy Queen.
            </p>
            <h5 class="mt-4" id="sign_of_the_cross">The Sign of the Cross</h5>
            <p>
              In the name of the Father of the Son and of the Holy Spirit. Amen
            </p>
            <h5 class="mt-4" id="apostles_creed">The Apostles’ Creed</h5>
            <p>
              I believe in God the Father Almighty, Creator of heaven and earth;
              and in Jesus Christ, His only Son, our Lord; Who was conceived by
              the Holy Ghost, born of the Virgin Mary, suffered under Pontius
              Pilate, was crucified, died and was buried. He descended into
              hell. On the third day He arose again; He ascended into heaven,and
              sitteth at the right hand of God, the Father Almighty; from thence
              He shall come to judge the living and the dead. I believe in the
              Holy Ghost, the Holy Catholic Church, the communion of saints, the
              forgivness of sins, the resurrection of the body, and life
              everlasting. Amen
            </p>
            <h5 class="mt-4" id="our_father">The Our Father</h5>
            <p>
              Our Father, who art in heaven, hallowed be Thy name: Thy kingdom
              come: Thy will be done on earth as it is in heaven. Give us this
              day our daily bread: and forgive us our trespasses as we forgive
              those who trespass against us. Do not let us fall into temptation,
              but deliver us from evil. Amen.
            </p>
            <h5 class="mt-4" id="hail_mary">The Hail Mary</h5>
            <p>
              Hail Mary, full of grace, the Lord is with thee: blessed art thou
              among women, and blessed is the fruit of thy womb, Jesus. Holy
              Mary, Mother of God, pray for us sinners, now and at the hour of
              our death. Amen
            </p>
            <h5 class="mt-4" id="glory_be">The Glory be</h5>
            <p>
              Glory be to the Father, and to the Son and to the Holy Spirit. As
              it was in the beginning, is now and ever shall be, world without
              end. Amen
            </p>
            <h5 class="mt-4" id="fatima_prayer">The Fatima prayer</h5>
            <p>
              “O my Jesus, forgive us our sins, save us from the fires of hell,
              and lead all souls to Heaven, especially those in most need of
              Your Mercy”.
            </p>
            <h5 class="mt-4" id="hail_holy_queen">The Hail Holy Queen</h5>
            <p>
              Hail, holy Queen, Mother of Mercy! our life, our sweetness, and
              our hope! To thee do we cry, poor banished children of Eve; to
              thee do we send up our sighs, mourning and weeping in this valley,
              of tears. Turn, then, most gracious Advocate, thine eyes of mercy
              toward us; and after this our exile show unto us the blessed fruit
              of thy womb, Jesus; O clement, O loving, O sweet Virgin Mary.
            </p>
            <h5 class="mt-4">Additional Rosary prayers</h5>
            <p>
              “O God whose only begotten Son by His Life has purchased for us
              the rewards of eternal life, Grant that we beseech Thee while
              meditating upon these mysteries of the Most Holy Rosary of the
              Blessed Virgin Mary, we may both imitate what they contain and
              obtain what they promise, through the same Christ our Lord
              Amen.”Fatima prayer #2 “My God, I believe, I adore, I hope, and I
              love You. I beg pardon of You for those who do not believe, do not
              adore, do not hope, and do not love You.”
            </p>
            <p>
              Remember, O most gracious Virgin Mary, that never was it known
              that anyone who fled to thy protection, implored thy help, or
              sought thine intercession was left unaided. Inspired by this
              confidence, I fly unto thee, O Virgin of virgins, my mother; to
              thee do I come, before thee I stand, sinful and sorrowful. O
              Mother of the Word Incarnate, despise not my petitions, but in thy
              mercy hear and answer me. Amen
            </p>
            <h5 class="mt-4">
              Litany of the Rosary of the Blessed Virgin Mary
            </h5>
            <div class="row pt-2">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="box-content">
                  <p
                    v-for="(obj, key) in prayerscontentJSON.slice(
                      0,
                      initialStep
                    )"
                    :key="key"
                  >
                    {{ obj.text }}
                  </p>
                  <a
                    v-if="visible"
                    class="read_more_link clickpad"
                    @click="viewPrayers"
                    >Continue Reading <ArrowDownOutlined class="continueArrow"
                  /></a>
                </div>
              </div>
            </div>
            <h5 class="mt-4">Memorare of St. Bernard</h5>
            <p>
              Remember, O most gracious Virgin Mary, that never was it known
              that anyone who fled to thy protection, implored thy help, or
              sought thine intercession was left unaided. Inspired by this
              confidence, I fly unto thee, O Virgin of virgins, my mother; to
              thee do I come, before thee I stand, sinful and sorrowful. O
              Mother of the Word Incarnate, despise not my petitions, but in thy
              mercy hear and answer me. Amen
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ArrowDownOutlined } from "@ant-design/icons-vue";
export default {
  components: {
    ArrowDownOutlined,
  },
  data: () => ({
    initialStep: 10,
    visible: true,
    prayerscontentJSON: [
      { text: "Lord have mercy." },
      { text: "Christ have mercy." },
      { text: "Lord have mercy." },
      { text: "Christ hear us." },
      { text: "Christ graciously hear us." },
      { text: "God, the Father of heaven," },
      { text: "have mercy on us." },
      { text: "God the Son, Redeemer of the world," },
      { text: "God the Holy Spirit," },
      { text: "Holy Trinity, one God," },
      { text: "Holy Mary," },
      { text: "pray for us." },
      { text: "Holy Mother of God," },
      { text: "Holy Virgin of virgins," },
      { text: "Mother of Christ," },
      { text: "Mother of the Church," },
      { text: "Mother of divine grace," },
      { text: "Mother most pure," },
      { text: "Mother most chaste," },
      { text: "Mother inviolate," },
      { text: "Mother undefiled," },
      { text: "Mother most amiable," },
      { text: "Mother admirable," },
      { text: "Mother of good counsel," },
      { text: "Mother of our Creator," },
      { text: "Mother of our Saviour," },
      { text: "Mother of mercy," },
      { text: "Virgin most prudent," },
      { text: "Virgin most venerable," },
      { text: "Virgin most renowned," },
      { text: "Virgin most powerful," },
      { text: "Virgin most merciful," },
      { text: "Virgin most faithful," },
      { text: "Mirror of justice," },
      { text: "Seat of wisdom," },
      { text: "Cause of our joy," },
      { text: "Spiritual vessel," },
      { text: "Vessel of honour," },
      { text: "Singular vessel of devotion," },
      { text: "Mystical rose," },
      { text: "Tower of David," },
      { text: "Tower of ivory," },
      { text: "House of gold," },
      { text: "Ark of the covenant," },
      { text: "Gate of heaven," },
      { text: "Morning star," },
      { text: "Health of the sick," },
      { text: "Refuge of sinners," },
      { text: "Comfort of the afflicted," },
      { text: "Help of Christians," },
      { text: "Queen of Angels," },
      { text: "Queen of Patriarchs," },
      { text: "Queen of Prophets," },
      { text: "Queen of Apostles," },
      { text: "Queen of Martyrs," },
      { text: "Queen of Confessors," },
      { text: "Queen of Virgins," },
      { text: "Queen of all Saints," },
      { text: "Queen conceived without original sin," },
      { text: "Queen assumed into heaven," },
      { text: "Queen of the most holy Rosary," },
      { text: "Queen of families," },
      { text: "Queen of peace." },
      { text: "Lamb of God, who takest away the sins of the world," },
      { text: "spare us, O Lord." },
      { text: "Lamb of God, who takest away the sins of the world," },
      { text: "graciously hear us, O Lord." },
      { text: "Lamb of God, who takest away the sins of the world," },
      { text: "have mercy on us." },
      { text: "Pray for us, O holy Mother of God." },
      {
        text: "That we may be made worthy of the promises of Christ.",
      },
      { text: "Let us pray." },
      { text: "Grant, we beseech thee," },
      { text: "O Lord God," },
      { text: "that we, your servants," },
      { text: "may enjoy perpetual health of mind and body;" },
      { text: "and by the intercession of the Blessed Mary, ever Virgin," },
      { text: "may be delivered from present sorrow," },
      { text: "and obtain eternal joy." },
      { text: "Through Christ our Lord." },
      { text: "Amen" },
    ],
  }),
  methods: {
    viewPrayers() {
      this.initialStep += 71;
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.read_more_link {
  display: block;
  margin-top: 0 !important;
  margin-bottom: -20px;
  font-weight: bold;
  border-radius: 4px;
  color: #0022bb;
}

.clickpad {
  padding: 13px;
  margin: -13px;
}
.box-content {
  background-color: #ffffff;
  margin: 0px auto;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 -1px 5px rgb(128 134 139 / 9%),
    0px 3px 5px rgb(128 134 139 / 6%), 0px 1px 2px rgb(60 64 67 / 30%),
    0px 1px 3px rgb(60 64 67 / 15%);
}
.cta-link {
  border-radius: 30px;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #0880e8;
  color: #0880e8;
}
h5 {
  font-family: "Eb Garamond", sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #0022bb;
}
.cta-link:hover {
  color: #fff;
  background-color: #0880e8;
}
.socialfooter {
  padding: 0px 0px 10px 0px !important;
}
.social-links a {
  margin-right: 20px;
}
.social-links {
  display: flex;
}
.saintheading {
  margin: 0 auto;
  padding: 50px;
}
select.selectpicker {
  padding: 5px 10px;
  font-size: 16px;
}
.about {
  padding: 30px 30px 30px 30px;
  background: #f9f9f9;
  position: relative;
}
.about:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .container {
  position: relative;
}

.about .content h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
}

@media (max-width: 992px) {
  .about {
    padding: 30px;
  }
}
</style>
